import { useEffect } from 'react';
import { useAccount } from '../hooks/useAccount';

const IS_PRODUCTION = import.meta.env.PROD;

export default function ScreenCapture() {
	const account = useAccount();

	useEffect(() => {
		if (IS_PRODUCTION && account?.shouldRecordScreen) {
			async function initiateReplay() {
				const { getClient, replayIntegration } = await import('@sentry/react');
				const client = getClient();
				if (client && !client.getIntegrationByName('Replay')) {
					client.addIntegration(
						replayIntegration({
							maskAllText: false,
							maskAllInputs: false,
							blockAllMedia: false,
							networkDetailAllowUrls: [window.location.origin]
						})
					);
				}
			}
			initiateReplay();
		}
	}, [account?.shouldRecordScreen]);

	return null;
}

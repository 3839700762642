import type { Optional } from '@segunosoftware/equinox';
import { useQueryClient, type QueryKey } from '@tanstack/react-query';

/**
 * Returns a callback that can update a single element in an array of cached data. The callback requires a query key that is assumed to
 * point to an array of elements. If the element to update is undefined, then the element will be removed from the array. Otherwise, if the
 * array does not exist, a new array will be created containing only the given element. Otherwise, if an element with the same "id" already
 * exists in the array, it will be replaced. Otherwise, the new element will be appended to the array. The updated array will then replace
 * the cached array.
 */
export function useUpdateQueryDataArray<T extends { id: number }>() {
	const queryClient = useQueryClient();
	return (queryKey: QueryKey, objectId: number, newObject?: T) =>
		queryClient.setQueryData<Optional<T[]>>(queryKey, currentArray => updateArrayEntry(currentArray, objectId, newObject));
}

export function updateArrayEntry<T extends { id: number }>(array: T[], entryId: number, newEntry?: T): T[];
export function updateArrayEntry<T extends { id: number }>(array: Optional<T[]>, entryId: number, newEntry?: T): Optional<T[]>;
export function updateArrayEntry<T extends { id: number }>(array: Optional<T[]>, entryId: number, newEntry?: T): Optional<T[]> {
	if (newEntry) {
		if (array && array.length > 0) {
			var index = array.findIndex(currentEntry => currentEntry.id === entryId);
			if (index === -1) {
				return [...array, newEntry];
			} else {
				return array.map(currentEntry => (currentEntry.id === newEntry.id ? newEntry : currentEntry));
			}
		} else {
			return [newEntry];
		}
	} else {
		return array?.filter(currentEntry => currentEntry.id !== entryId);
	}
}

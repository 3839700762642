import {
	Duration,
	NO_OP,
	getDateFromTimestamp,
	isNotBlank,
	queryKey,
	type AnyFn,
	type ExtractExact,
	type Merge,
	type Optional,
	type Timestamp
} from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient, type Query, type QueryFilters } from '@tanstack/react-query';
import queryString from 'query-string';
import InvalidationRegistry from '../../utils/InvalidationRegistry';
import { Assert } from '../../utils/utils';
import type { EmailConfiguration, FeaturedContent } from '../emailTemplates/email-template-types';
import {
	AUTOMATION_FLOW_ACTIONS,
	CAN_CONFIGURE_TRANSACTIONAL_SENDING,
	PROGRAMS,
	PROGRAM_MESSAGE,
	PROGRAM_MESSAGES,
	PROGRAM_STATE_SUMMARY
} from '../query-keys';
import type { Delete, DeleteEndpoint, ErrorResponse, Get, MarketingActivityConfiguration, Post, Put } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import type { DiscountCodeSettings, DiscountCodeType, DiscountEligibility } from '../useDiscountCodes';
import { useUpdateQueryDataArray } from '../useUpdateQueryDataArray';

export const POST_PURCHASE_AUTOMATION_EXTENSION_UUID: string | undefined = import.meta.env.VITE_POST_PURCHASE_AUTOMATION_EXTENSION_UUID;

export const TAG_AUTOMATION_EXTENSION_UUID: string | undefined = import.meta.env.VITE_TAG_AUTOMATION_EXTENSION_UUID;

export const AUTOMATION_TRIGGER_LIMITS_ENABLED = import.meta.env.VITE_AUTOMATION_TRIGGER_LIMITS_ENABLED === '1';

export type ProgramType =
	| 'COMING_SOON'
	| 'WELCOME'
	| 'DISCOUNT_REMINDER'
	| 'ABANDONED_CHECKOUT'
	| 'FIRST_PURCHASER'
	| 'REPEAT_PURCHASER'
	| 'REVIEW_REQUEST'
	| 'LAPSED_PURCHASER'
	| 'TAG_TRIGGER'
	| 'POST_PURCHASE'
	| 'ABANDONMENT'
	| 'BACK_IN_STOCK'
	| 'SEND_MARKETING_EMAIL';

export const FLOW_ACTION_PROGRAM_TYPES = Object.freeze([
	'ABANDONMENT',
	'BACK_IN_STOCK',
	'SEND_MARKETING_EMAIL'
] as const satisfies readonly ProgramType[]);

export const isFlowActionProgramType = (programType?: ProgramType): programType is (typeof FLOW_ACTION_PROGRAM_TYPES)[number] =>
	Boolean(programType && FLOW_ACTION_PROGRAM_TYPES.includes(programType as any));

export const isDuplicatable = (programType?: ProgramType) => !isFlowActionProgramType(programType) && programType !== 'REVIEW_REQUEST';

export const NEWSLETTER_EXCLUSION_ALLOWED_PROGRAM_TYPES: ProgramType[] = ['WELCOME', 'TAG_TRIGGER', 'ABANDONED_CHECKOUT'];
export const MAX_TRIGGER_COUNT_CONFIGURABLE_PROGRAM_TYPES: ProgramType[] = ['SEND_MARKETING_EMAIL', 'POST_PURCHASE', 'TAG_TRIGGER'];

export type DiscountReminderEligibility = 'ALWAYS' | 'MONTHLY';

export type PrerequisiteOrderState = 'CREATED' | 'FULFILLED';

export type ProgramSettings = Partial<{
	eligibility: DiscountReminderEligibility;
	EXCLUDE_CAMPAIGN_GROUPS: boolean;
	MAX_TRIGGER_COUNT_PER_CUSTOMER: number;
	SEND_FIRST_MESSAGE_TRANSACTIONALLY: boolean;
	skip_welcome: boolean;
	productId: number;
	productCollectionId: number;
	PREREQUISITE_ORDER_STATE: PrerequisiteOrderState;
	tag: string;
}>;

export const SKIP_PURCHASERS_NOT_REQUIRED = [
	'ABANDONED_CHECKOUT',
	'FIRST_PURCHASER',
	'REPEAT_PURCHASER',
	'REVIEW_REQUEST',
	'LAPSED_PURCHASER',
	'DISCOUNT_REMINDER'
];

export type NodeType = 'DELAY' | 'DELETED' | 'MESSAGE' | 'MODIFY_TAGS';

export interface DisableableMetadata {
	disabled: boolean;
}

export interface NodeMetadata<T extends NodeType> {
	type: T;
}

export interface DeletedNodeMetadata extends NodeMetadata<'DELETED'> {}

export interface DelayNodeMetadata extends NodeMetadata<'DELAY'>, DisableableMetadata {
	delay: number;
}

export interface MessageNodeMetadata extends NodeMetadata<'MESSAGE'>, DisableableMetadata {
	messageId: number;
}

export interface ModifyTagsNodeMetadata extends NodeMetadata<'MODIFY_TAGS'>, DisableableMetadata {
	additions: string[];
	removals: string[];
}

export type StateNode<N extends NodeType> = {
	id: string;
	on: Record<string, string>;
	meta: N extends 'DELAY'
		? DelayNodeMetadata
		: N extends 'MESSAGE'
			? MessageNodeMetadata
			: N extends 'MODIFY_TAGS'
				? ModifyTagsNodeMetadata
				: DeletedNodeMetadata;
};

export type StateMachine = {
	initial: string;
	states: Record<string, StateNode<NodeType>>;
};

export type Program = {
	id: number;
	name: string;
	description: string;
	position: number;
	programType: ProgramType;
	enabled: boolean;
	triggerEnabled: boolean;
	allowNewsletterSending: boolean;
	paid: boolean;
	skipPurchasers: boolean;
	marketingActivityId?: string;
	marketingEventId?: number;
	externallyCreated?: boolean;
	tagExclusions: string[];
	segmentExclusions: string[];
	settings: { settings: ProgramSettings };
	stateDefinition: StateMachine;
	triggerDelay: number;
	createdAt: Date;
	updatedAt: Date;
	enabledChangedAt: Date;
};

type DehydratedProgram = Merge<
	Program,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
		enabledChangedAt: Timestamp;
	}
>;

const hydrateProgram = (dehydratedProgram: DehydratedProgram): Program => {
	Assert.isDefined(dehydratedProgram);
	Assert.isRealNumber(dehydratedProgram.id);
	Assert.isRealNumber(dehydratedProgram.createdAt);
	Assert.isRealNumber(dehydratedProgram.updatedAt);
	return {
		...dehydratedProgram,
		createdAt: getDateFromTimestamp(dehydratedProgram.createdAt)!,
		updatedAt: getDateFromTimestamp(dehydratedProgram.updatedAt)!,
		enabledChangedAt: getDateFromTimestamp(dehydratedProgram.enabledChangedAt)!
	};
};

const hydratePrograms = (dehydratedPrograms: DehydratedProgram[]) => {
	Assert.isArray(dehydratedPrograms);
	return dehydratedPrograms.map(hydrateProgram);
};

export type ProgramMessage = {
	id: number;
	programId: number;
	emailTemplateId: number;
	discountCodeId: number;
	priceRuleId: number;
	discountCodeType: keyof typeof DiscountCodeType;
	discountEligibility: keyof typeof DiscountEligibility;
	autoExpireDisabled: boolean;
	autoExpireDays: number;
	templateModified: boolean;
	createdAt: Date;
	updatedAt: Date;
};

type DehydratedProgramMessage = Merge<
	ProgramMessage,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
	}
>;

export type FlowActionType = ExtractExact<ProgramType, 'ABANDONMENT' | 'BACK_IN_STOCK' | 'SEND_MARKETING_EMAIL'>;

export type AutomationFlowAction = {
	workflowId: string;
	stepReference: string;
	marketingActivityId: string;
	flowActionType: FlowActionType;
	programId: number;
};

function hydrateProgramMessage(dehydratedProgramMessage: DehydratedProgramMessage): ProgramMessage {
	Assert.isDefined(dehydratedProgramMessage);
	Assert.isRealNumber(dehydratedProgramMessage.id);
	Assert.isRealNumber(dehydratedProgramMessage.emailTemplateId);
	Assert.isRealNumber(dehydratedProgramMessage.createdAt);
	Assert.isRealNumber(dehydratedProgramMessage.updatedAt);
	return {
		...dehydratedProgramMessage,
		createdAt: getDateFromTimestamp(dehydratedProgramMessage.createdAt)!,
		updatedAt: getDateFromTimestamp(dehydratedProgramMessage.updatedAt)!
	};
}

function hydrateProgramMessages(dehydratedProgramMessages: DehydratedProgramMessage[]): ProgramMessage[] {
	Assert.isArray(dehydratedProgramMessages);
	return dehydratedProgramMessages.map(hydrateProgramMessage);
}

export type CreateProgramRequest = {
	name: string;
	programType: ProgramType;
	triggerDelayMinutes: number;
	stepReference?: string;
	programSettings: ProgramSettings;
	emailConfiguration: EmailConfiguration;
	marketingActivityConfiguration: MarketingActivityConfiguration;
	featuredContent: FeaturedContent;
	discountCodeSettings: DiscountCodeSettings;
};

export type OnCreateProgramSuccess = (program: Program) => void;

export function useCreateProgram(onSuccess?: OnCreateProgramSuccess) {
	type PostRequest = Merge<CreateProgramRequest, { programSettings: Program['settings'] }>;
	const { post } = useAuthenticatedFetch() as Post<PostRequest, DehydratedProgram>;
	const updateCachedProgram = useUpdateCachedProgram();

	const mutation = useMutation<Program, ErrorResponse, CreateProgramRequest>({
		mutationFn: ({ programSettings, ...createProgramRequest }) =>
			post('/programs/v2', { ...createProgramRequest, programSettings: { settings: programSettings } }).then(hydrateProgram),
		onSuccess: program => {
			updateCachedProgram(program.id, program);
			onSuccess && onSuccess(program);
		}
	});

	return {
		createProgram: (createProgramRequest: CreateProgramRequest) => mutation.mutate(createProgramRequest),
		isCreatingProgram: mutation.isPending,
		isCreateProgramSuccess: mutation.isSuccess,
		serverSideErrors: mutation.error?.body ?? {},
		createdProgram: mutation.data
	};
}

export function useProgram(programId: Optional<number>, prefetchPrograms: boolean = false) {
	const { programs, isLoading } = usePrograms(prefetchPrograms || Boolean(programId));
	return {
		program: programs?.find((program: Program) => program?.id === programId),
		isLoading: isLoading
	};
}

export function useProgramByMarketingActivityId(marketingActivityId: Optional<string>) {
	const { programs, isLoading } = usePrograms(isNotBlank(marketingActivityId));
	return {
		program: programs?.find((program: Program) => program?.marketingActivityId === marketingActivityId),
		isLoading: isLoading
	};
}

export function useAutomationFlowAction(stepReference: string) {
	const { get } = useAuthenticatedFetch() as Get<AutomationFlowAction>;

	const url = queryString.stringifyUrl({ url: `/flow-actions/automation-trigger/${stepReference}` });
	const query = useQuery({
		queryKey: queryKey(AUTOMATION_FLOW_ACTIONS, stepReference),
		queryFn: () => get(url),
		enabled: isNotBlank(stepReference)
	});

	return {
		automationFlowAction: query.data,
		isLoading: query.isFetching,
		reload: () => query.refetch()
	};
}

export function usePrograms(enabled = true) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedProgram[]>;
	const query = useQuery({ queryKey: queryKey(PROGRAMS), queryFn: () => get('/programs').then(hydratePrograms), enabled });
	return {
		programs: query.data ?? [],
		isLoading: query.isFetching
	};
}

export function useUpdateProgram() {
	const { put } = useAuthenticatedFetch() as Put<Program, DehydratedProgram>;
	const updateCachedProgram = useUpdateCachedProgram();

	const mutation = useMutation<Program, ErrorResponse, Program>({
		mutationFn: program => put(`/programs/${program.id}`, program).then(hydrateProgram),
		onSuccess: program => updateCachedProgram(program.id, program)
	});

	return {
		updateProgram: (program: Program) => mutation.mutate(program),
		isProgramUpdating: mutation.isPending,
		programUpdateError: mutation.error?.body ?? {},
		reset: mutation.reset
	};
}

export function useDeleteProgram() {
	const { delete: deleteCall } = useAuthenticatedFetch() as Delete;
	const updateCachedProgram = useUpdateCachedProgram(true);

	const mutation = useMutation<void, ErrorResponse, { programId: number }>({
		mutationFn: ({ programId }) => deleteCall(`/programs/${programId}`),
		onSuccess: (_, { programId }) => updateCachedProgram(programId)
	});

	return {
		deleteProgram: (programId: number) => mutation.mutate({ programId }),
		isProgramDeleting: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

export function useCanConfigureTransactionalSending(programId: Optional<number>) {
	const { get } = useAuthenticatedFetch() as Get<boolean>;
	const query = useQuery({
		queryKey: queryKey(CAN_CONFIGURE_TRANSACTIONAL_SENDING, programId),
		queryFn: () => get(`/programs/${programId}/can-configure-transactional-sending`),
		enabled: Boolean(programId)
	});
	return { canConfigureTransactionalSending: query.data, isLoading: query.isFetching };
}

export type ProgramStateSummary = {
	id: number;
	stateCounts: Record<string, number>;
};

export function useProgramStateSummary(programId: number) {
	const { get } = useAuthenticatedFetch() as Get<ProgramStateSummary>;
	const query = useQuery({
		queryKey: queryKey(PROGRAM_STATE_SUMMARY, programId),
		queryFn: () => get(`/programs/${programId}/state-summary`),
		enabled: Boolean(programId)
	});
	return { programStateSummary: query.data, isProgramStateSummaryLoading: query.isLoading };
}

export function useProgramEnablementToggle() {
	type PostBody = { enabled: boolean };
	type MutationProps = PostBody & { programId: number };

	const { post } = useAuthenticatedFetch() as Post<PostBody, DehydratedProgram>;
	const updateCachedProgram = useUpdateCachedProgram();

	const mutation = useMutation<Program, ErrorResponse, MutationProps>({
		mutationFn: ({ programId, enabled }) => post(`/programs/${programId}/enablement`, { enabled }).then(hydrateProgram),
		onSuccess: program => updateCachedProgram(program.id, program)
	});

	return {
		toggleProgramEnablement: (programId: number, enabled: boolean) => mutation.mutate({ programId, enabled }),
		isTogglingProgramEnablement: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

export function useUpdateProgramStateMachine() {
	const { put } = useAuthenticatedFetch() as Put<StateMachine, DehydratedProgram>;
	const updateCachedProgram = useUpdateCachedProgram();

	const mutation = useMutation<Program, ErrorResponse, { programId: number; stateMachine: StateMachine }>({
		mutationFn: ({ programId, stateMachine }) => put(`/programs/${programId}/state`, stateMachine).then(hydrateProgram),
		onSuccess: program => updateCachedProgram(program.id, program)
	});

	return {
		updateProgramStateMachine: (programId: number, stateMachine: StateMachine) => mutation.mutate({ programId, stateMachine }),
		isProgramStateMachineUpdating: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

function registerProgramMessage(programMessage: ProgramMessage): ProgramMessage {
	InvalidationRegistry.register(
		'programMessage',
		programMessage.id,
		queryKey(PROGRAM_MESSAGE, programMessage.id),
		queryKey(PROGRAM_MESSAGES, programMessage.programId)
	).observe('emailTemplate', programMessage.emailTemplateId, true);
	return programMessage;
}

function registerProgramMessages(programMessages: ProgramMessage[]) {
	programMessages.forEach(registerProgramMessage);
	return programMessages;
}

export function useProgramMessage(programMessageId: Optional<number>, enabled = true) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedProgramMessage>;
	const { data: programMessage, isFetching: isLoading } = useQuery({
		queryKey: queryKey(PROGRAM_MESSAGE, programMessageId),
		queryFn: () => get(`/program-messages/${programMessageId}`).then(hydrateProgramMessage),
		enabled: enabled && Boolean(programMessageId),
		select: registerProgramMessage
	});
	return {
		programMessage,
		isLoading
	};
}

export function useProgramMessages(programId: number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedProgramMessage[]>;
	const qk = queryKey(PROGRAM_MESSAGES, programId);
	const query = useQuery({
		queryKey: qk,
		queryFn: () => get(`/programs/${programId}/messages`).then(hydrateProgramMessages),
		select: registerProgramMessages
	});
	return {
		programMessages: query.data || [],
		isLoading: query.isFetching
	};
}

export function useUpdateProgramMessage(onSuccessHandler?: () => void) {
	const { put } = useAuthenticatedFetch() as Put<ProgramMessage, DehydratedProgramMessage>;
	const updateCachedProgramMessage = useUpdateCachedProgramMessage();

	const mutation = useMutation<ProgramMessage, ErrorResponse, ProgramMessage>({
		mutationFn: programMessage => put(`/program-messages/${programMessage.id}`, programMessage).then(hydrateProgramMessage),
		onSuccess: programMessage => {
			updateCachedProgramMessage(programMessage);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		updateProgramMessage: (programMessage: ProgramMessage) => mutation.mutate(programMessage),
		isUpdatingProgramMessage: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		isError: mutation.isError,
		resetMutation: mutation.reset
	};
}

export function useDuplicateProgramMessage() {
	const { post } = useAuthenticatedFetch() as Post<void, DehydratedProgram>;
	const updateCachedProgram = useUpdateCachedProgram(true);

	const mutation = useMutation<Program, ErrorResponse, { programId: number; programMessageId: number }>({
		mutationFn: ({ programId, programMessageId }) =>
			post(`/programs/${programId}/messages/${programMessageId}/duplicate`).then(hydrateProgram),
		onSuccess: program => updateCachedProgram(program.id, program)
	});

	return {
		duplicateProgramMessage: (programId: number, programMessageId: number) => mutation.mutate({ programId, programMessageId }),
		isDuplicatingProgramMessage: mutation.isPending
	};
}

export type CreateProgramMessageFromTemplateRequest = {
	templateId: number;
	subject: string;
	previewText: string;
};

export function useCopyToProgramMessage(onSuccess: AnyFn = NO_OP) {
	const { post } = useAuthenticatedFetch() as Post<CreateProgramMessageFromTemplateRequest, Program>;
	const updateCachedProgram = useUpdateCachedProgram(true);

	const { mutate: copyToProgramMessage, isPending: isCopying } = useMutation<
		Program,
		ErrorResponse,
		{ id: number; request: CreateProgramMessageFromTemplateRequest }
	>({
		mutationFn: ({ id, request }) => post(`/programs/${id}/messages/create`, request),
		onSuccess: program => {
			updateCachedProgram(program.id, program);
			onSuccess(program);
		}
	});

	return { copyToProgramMessage, isCopying };
}

/**
 * Exports a template or group of templates as a campaign
 */
export function useProgramExport(onSuccessHandler?: () => void) {
	const { get } = useAuthenticatedFetch();
	const mutation = useMutation<void, ErrorResponse, { programId: number; exportName: string }>({
		mutationFn: ({ programId, exportName }) =>
			get(
				queryString.stringifyUrl({ url: `/programs/${programId}/export`, query: { name: exportName } }),
				{
					download: true,
					filename: `${exportName.replaceAll(' ', '_').toUpperCase()}.zip`
				},
				{
					Accept: 'application/zip'
				}
			),
		onSuccess: () => onSuccessHandler && onSuccessHandler()
	});

	return {
		exportProgram: (programId: number, exportName: string) => mutation.mutate({ programId, exportName }),
		isExporting: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

export type CreateOrUpdateStateMachineNodeRequest = {
	type: 'MODIFY_TAGS'; // for now, this is all we allow
	stateNodeId?: string;
	delay?: Duration;
};

export type CreateOrUpdateModifyTagsNodeRequest = CreateOrUpdateStateMachineNodeRequest & {
	additions: string[];
	removals: string[];
};

export function useCreateOrUpdateStateMachineNode(programId: number, onSuccessHandler?: () => void) {
	const { post } = useAuthenticatedFetch() as Post<CreateOrUpdateStateMachineNodeRequest, Program>;
	const updateCachedProgram = useUpdateCachedProgram(); // we are only creating modify tag nodes here, so the PMs are okay

	const mutation = useMutation<Program, ErrorResponse, CreateOrUpdateStateMachineNodeRequest>({
		mutationFn: request => post(`/programs/${programId}/state-machine/nodes:create-or-update`, request),
		onSuccess: program => {
			updateCachedProgram(program.id, program);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		createOrUpdateStateMachineNode: <R extends CreateOrUpdateStateMachineNodeRequest>(request: R) => mutation.mutate(request),
		isCreatingOrUpdatingStateMachineNode: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

export function useDeleteStateMachineNode(onSuccessHandler?: () => void) {
	const del = useAuthenticatedFetch().delete as DeleteEndpoint<Program>;
	const updateCachedProgram = useUpdateCachedProgram(true);

	const mutation = useMutation<Program, ErrorResponse, { programId: number; nodeId: string }>({
		mutationFn: ({ programId, nodeId }) => del(`/programs/${programId}/state-machine/nodes/${nodeId}`, { parseResponse: true }),
		onSuccess: program => {
			updateCachedProgram(program.id, program);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		deleteStateMachineNode: (programId: number, nodeId: string) => mutation.mutate({ programId, nodeId }),
		isDeletingStateMachineNode: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error?.body ?? {}
	};
}

function useUpdateCachedProgramMessage() {
	const queryClient = useQueryClient();
	const updateQueryDataArray = useUpdateQueryDataArray<ProgramMessage>();

	function callback(programMessage: ProgramMessage): void;
	function callback(programId: number, programMessageId: number): void;
	function callback(arg1: ProgramMessage | number, arg2?: number) {
		const programMessage = typeof arg1 === 'number' ? undefined : arg1;
		const programId = typeof arg1 === 'number' ? arg1 : arg1.programId;
		const programMessageId = programMessage ? programMessage.id : arg2!;

		Assert.isRealNumber(programId);
		Assert.isRealNumber(programMessageId);

		const programMessageQueryKey = queryKey(PROGRAM_MESSAGE, programMessageId);

		if (programMessage) {
			queryClient.setQueryData<Optional<ProgramMessage>>(programMessageQueryKey, programMessage);
		} else {
			queryClient.removeQueries({ queryKey: programMessageQueryKey });
		}

		updateQueryDataArray(queryKey(PROGRAM_MESSAGES, programId), programMessageId, programMessage);
		InvalidationRegistry.get('programMessage', programMessageId).invalidateObservers();
	}

	return callback;
}

function useUpdateCachedProgram(invalidateProgramMessages = false) {
	const queryClient = useQueryClient();
	const updateQueryDataArray = useUpdateQueryDataArray<Program>();
	return (programId: number, program?: Program) => {
		updateQueryDataArray(queryKey(PROGRAMS), programId, program);
		if (invalidateProgramMessages && program) {
			queryClient.invalidateQueries({ queryKey: queryKey(PROGRAM_MESSAGES, programId) });
			queryClient.invalidateQueries({
				queryKey: queryKey(PROGRAM_MESSAGE),
				predicate: ((query: Query<ProgramMessage>) => query.state.data?.programId === programId) as QueryFilters['predicate']
			});
		}
	};
}

import { QueryCache, QueryClient } from '@tanstack/react-query';

/**
 * Creates a new QueryClient with the default configuration.
 * @returns {QueryClient} The default query client.
 */

type Logger = {
	onError: (message: any) => void;
};

export const createQueryClient = (logger?: Logger): QueryClient => {
	return new QueryClient({
		queryCache: new QueryCache({ ...logger }),
		defaultOptions: {
			queries: {
				refetchOnMount: true,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				retry: false,
				staleTime: Infinity
			}
		}
	});
};

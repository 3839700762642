import { LocalDate, deepEquals, isNotBlank, queryKey, type OmitExact, type Optional } from '@segunosoftware/equinox';
import { keepPreviousData, useMutation, useQuery, useQueryClient, type Query, type QueryFilters } from '@tanstack/react-query';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import InvalidationRegistry from '../../utils/InvalidationRegistry';
import { Assert } from '../../utils/utils';
import type { EmailConfiguration, FeaturedContent, PreviewContext } from '../emailTemplates/email-template-types';
import {
	CAMPAIGNS,
	CAMPAIGN_GROUPS,
	CAMPAIGN_PREVIEWS,
	CAMPAIGN_RECIPIENTS,
	CAMPAIGN_SEARCH,
	FOLLOWUP_CAMPAIGNS,
	MutationKey,
	WARMING_CAMPAIGN
} from '../query-keys';
import type { ErrorResponse, Get, MarketingActivityConfiguration, Pagination, Patch, Post, Put } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import { discountCodeIsPresent, useDiscountCodeById, type DiscountCodeSettings, type DiscountCodeTypeValues } from '../useDiscountCodes';
import { updateArrayEntry } from '../useUpdateQueryDataArray';
import {
	hydrateCampaign,
	type Campaign,
	type CampaignGroup,
	type CampaignStatusValues,
	type DehydratedCampaign,
	type TargetRecipientsValues
} from './campaign-types';

/** @deprecated */
export const CampaignStatus = Object.freeze({
	CREATED: 'CREATED',
	PAUSED: 'PAUSED',
	SCHEDULED: 'SCHEDULED',
	PROCESSING: 'PROCESSING',
	COMPLETED: 'COMPLETED'
});

/** @deprecated */
export const TargetRecipients = Object.freeze({
	ALL: 'ALL',
	CUSTOMER_SAVED_SEARCHES: 'CUSTOMER_SAVED_SEARCHES',
	INHERITED_DELIVERIES: 'INHERITED_DELIVERIES',
	INHERITED_OPENS: 'INHERITED_OPENS',
	INHERITED_CLICKS: 'INHERITED_CLICKS',
	INHERITED_NON_OPENS: 'INHERITED_NON_OPENS',
	INHERITED_NON_CLICKS: 'INHERITED_NON_CLICKS'
});

export type RemailSettings = {
	subject: string;
	previewText: string;
	remailDelay: string;
};

export type ScheduleSettings = {
	subject: string;
	previewText: string;
	scheduledAt: Date;
	remailSettings: RemailSettings;
};

function hydrateCampaigns(dehydratedCampaigns: DehydratedCampaign[]): Campaign[] {
	Assert.isArray(dehydratedCampaigns);
	return dehydratedCampaigns.map(hydrateCampaign);
}

export function useOnCampaignUpdated({
	cacheByCampaignId = true,
	cacheByMarketableEventId = true,
	invalidateEmailTemplate = false,
	invalidateFollowupParent = false
} = {}) {
	const queryClient = useQueryClient();

	function onUpdateCampaign(arg1: number | Campaign): void {
		const [campaignId, campaign] = typeof arg1 === 'number' ? [arg1] : [arg1.id, arg1];

		Assert.isRealNumber(campaignId);
		campaign && Assert.isTruthy(campaign.id === campaignId);

		if (campaign) {
			if (cacheByCampaignId) {
				queryClient.setQueryData(queryKey(CAMPAIGNS, campaignId), campaign);
			}

			if (cacheByMarketableEventId && isNotBlank(campaign.marketingActivityId)) {
				queryClient.setQueryData(queryKey(CAMPAIGNS, campaign.marketingActivityId), campaign);
			}

			if (invalidateEmailTemplate) {
				InvalidationRegistry.get('emailTemplate', campaign.emailTemplateId).invalidate();
			}

			if (invalidateFollowupParent && campaign.parentCampaignId) {
				queryClient.invalidateQueries({ queryKey: queryKey(FOLLOWUP_CAMPAIGNS, campaign.parentCampaignId) });
			}
		} else {
			// also includes those indexed by marketing activity id
			queryClient.removeQueries({
				queryKey: queryKey(CAMPAIGNS),
				predicate: ((query: Query<Campaign>) => query.state.data?.id === campaignId) as QueryFilters['predicate']
			});

			// TODO (invalidateFollowupParent): this is not easy, since we don't always have an easy way to find the parent
			queryClient.invalidateQueries({ queryKey: queryKey(FOLLOWUP_CAMPAIGNS) }); // all of them
		}

		queryClient.invalidateQueries({ queryKey: queryKey(CAMPAIGN_SEARCH) }); // all pages for all searches
		queryClient.removeQueries({ queryKey: queryKey(CAMPAIGN_RECIPIENTS, campaignId) });
		queryClient.removeQueries({ queryKey: queryKey(CAMPAIGN_PREVIEWS, campaignId) });

		if (!campaign || campaign.campaignGroupId) {
			queryClient.setQueryData<Optional<CampaignGroup>>(queryKey(CAMPAIGN_GROUPS), campaignGroup => {
				if (campaignGroup) {
					return {
						...campaignGroup,
						campaigns: updateArrayEntry(campaignGroup.campaigns, campaignId, campaign)
					};
				}
				return campaignGroup;
			});
		}
	}

	return {
		onCampaignUpdated: (campaign: Campaign) => onUpdateCampaign(campaign),
		onCampaignDeleted: (campaignId: number) => onUpdateCampaign(campaignId)
	};
}

export function useCampaign(campaignId: Optional<number>) {
	const { onCampaignUpdated } = useOnCampaignUpdated({ cacheByCampaignId: false });

	const { get } = useAuthenticatedFetch() as Get<DehydratedCampaign>;
	const {
		data,
		isFetching: isLoadingCampaign,
		refetch
	} = useQuery({
		queryKey: queryKey(CAMPAIGNS, campaignId),
		queryFn: () =>
			get(`/newsletters/${campaignId}`).then(data => {
				const campaign = hydrateCampaign(data);
				onCampaignUpdated(campaign);
				return campaign;
			}),
		enabled: Boolean(campaignId)
	});

	return {
		campaign: data,
		isLoadingCampaign,
		reloadCampaign: refetch
	};
}

export function useWarmingCampaign() {
	const { onCampaignUpdated } = useOnCampaignUpdated({ cacheByCampaignId: false });

	const { get } = useAuthenticatedFetch() as Get<DehydratedCampaign>;
	const { data, isFetching } = useQuery({
		queryKey: queryKey(WARMING_CAMPAIGN),
		queryFn: () =>
			get('/newsletters/warming-send').then(data => {
				const campaign = hydrateCampaign(data);
				onCampaignUpdated(campaign);
				return campaign;
			})
	});

	return {
		warmingCampaign: data,
		isLoadingWarmingCampaign: isFetching
	};
}

export function useCampaignByMarketingActivityId(marketingActivityId?: string) {
	const { onCampaignUpdated } = useOnCampaignUpdated({ cacheByMarketableEventId: false });

	const { get } = useAuthenticatedFetch() as Get<DehydratedCampaign>;
	const url = queryString.stringifyUrl({ url: '/newsletters/activity', query: { id: marketingActivityId } });

	const { data, isFetching } = useQuery({
		queryKey: queryKey(CAMPAIGNS, marketingActivityId),
		queryFn: () =>
			get(url).then(data => {
				const campaign = hydrateCampaign(data);
				onCampaignUpdated(campaign);
				return campaign;
			}),
		enabled: isNotBlank(marketingActivityId)
	});

	return {
		campaign: data,
		isLoadingCampaign: isFetching
	};
}

export type CreateCampaignRequest = {
	name: string;
	campaignGroupId?: number;
	emailConfiguration: EmailConfiguration;
	marketingActivityConfiguration: MarketingActivityConfiguration;
	featuredContent: FeaturedContent;
	discountCodeSettings: DiscountCodeSettings;
	includedSegmentSids?: string[];
	excludedSegmentSids?: string[];
};

export function useCreateCampaign(onSuccessHandler?: (campaign: Campaign) => void) {
	const { post } = useAuthenticatedFetch() as Post<CreateCampaignRequest, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ cacheByCampaignId: false });

	const mutation = useMutation<Campaign, ErrorResponse, CreateCampaignRequest>({
		mutationFn: createCampaignRequest => post('/newsletters/v2', createCampaignRequest).then(hydrateCampaign),
		onSuccess: campaign => {
			onCampaignUpdated(campaign);
			onSuccessHandler && onSuccessHandler(campaign);
		}
	});

	return {
		createCampaign: (createCampaignRequest: CreateCampaignRequest) => mutation.mutate(createCampaignRequest),
		isCreatingCampaign: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {}
	};
}

export type DuplicateCampaignVariables = {
	id: number;
	name: string;
	subject: string;
	previewText: string;
	targetRecipients: TargetRecipientsValues;
	discountCodeType: DiscountCodeTypeValues;
};

export function useDuplicateCampaign(onSuccessHandler?: (campaign: Campaign) => {}) {
	const { post } = useAuthenticatedFetch() as Post<OmitExact<DuplicateCampaignVariables, 'id'>, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ cacheByCampaignId: false, invalidateFollowupParent: true });

	const mutation = useMutation<Campaign, ErrorResponse, DuplicateCampaignVariables>({
		mutationFn: ({ id, ...body }) => post(`/newsletters/${id}/duplicate`, body).then(hydrateCampaign),
		onSuccess: campaign => {
			onCampaignUpdated(campaign);
			onSuccessHandler && onSuccessHandler(campaign);
		}
	});

	return {
		duplicateCampaign: (variables: DuplicateCampaignVariables) => mutation.mutate(variables),
		isDuplicatingCampaign: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		resetMutation: mutation.reset
	};
}

export type CampaignSearchSortOrder =
	| 'NEWEST_UPDATE'
	| 'OLDEST_UPDATE'
	| 'NAME_AZ'
	| 'NAME_ZA'
	| 'NEWEST_SEND_COMPLETED'
	| 'OLDEST_SEND_COMPLETED'
	| 'NEWEST_SCHEDULED'
	| 'OLDEST_SCHEDULED';

export type CampaignSearch = Partial<{
	/** Maximum number of results to fetch per page. */
	limit: number;
	sortOrder: CampaignSearchSortOrder;
	/** Filter by campaign membership; use 0 to exclude newsletters that already belong to a campaign. */
	campaignGroupId: number;
	campaignTypeEmailTemplateId: number;
	/** Select only those campaigns which have one of these campaign statuses. */
	campaignStatuses: CampaignStatusValues[];
	/** if true, remails will be excluded. */
	excludeRemails: boolean;
	/** Lower bound for filtering by sendCompletedAt date */
	sendCompletedAtFrom: LocalDate;
	/** Upper bound for filtering by sendCompletedAt date */
	sendCompletedAtTo: LocalDate;
}>;

export type CampaignSearchResults = {
	campaigns: Campaign[];
	totalResults: number;
	page: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	getPreviousPage?: () => void;
	getNextPage?: () => void;
};

// TODO: Make this be a generic search function that can be used for other kinds of result sets
export function useSearchCampaigns(searchProps: CampaignSearch = {}, enabled = true, debounceMillis = 500, initialSearchTerms?: string) {
	type SearchPayload = Omit<CampaignSearch, 'sendCompletedAtFrom' | 'sendCompletedAtTo'> & {
		searchTerms?: string;
		page: number;
		sendCompletedAtFrom?: string;
		sendCompletedAtTo?: string;
	};
	type ResponseBody = { campaigns: DehydratedCampaign[]; totalResults: number };
	type QueryReturn = { campaigns: Campaign[]; totalResults: number };

	const { post } = useAuthenticatedFetch() as Post<SearchPayload, ResponseBody>;
	const [page, setPage] = useState(0);

	// handle search term changes...

	const [searchTerms, setSearchTerms] = useState(initialSearchTerms);
	const [cachedSearchTerms, setCachedSearchTerms] = useState(searchTerms);

	const hasDifferentSearchTerms = cachedSearchTerms !== searchTerms;

	useEffect(() => {
		if (hasDifferentSearchTerms) {
			if (debounceMillis) {
				const searchDebounce = setTimeout(() => {
					setCachedSearchTerms(searchTerms);
					setPage(0);
				}, debounceMillis);
				return () => clearTimeout(searchDebounce);
			} else {
				setCachedSearchTerms(searchTerms);
				setPage(0);
			}
		}
		return undefined;
	}, [debounceMillis, hasDifferentSearchTerms, searchTerms]);

	// handle changes to the rest of the search props...

	const { ...searchPropsCopy } = searchProps;
	const [cachedSearchProps, setCachedSearchProps] = useState(searchPropsCopy);
	const hasDifferentSearchProps = !deepEquals(cachedSearchProps, searchPropsCopy);

	useEffect(() => {
		if (hasDifferentSearchProps) {
			setCachedSearchProps(searchPropsCopy);
			setPage(0);
		}
	}, [hasDifferentSearchProps, searchPropsCopy]);

	// build the search payload...

	const pageSize = cachedSearchProps.limit ?? 50;
	const searchPayload: SearchPayload = {
		...cachedSearchProps,
		sendCompletedAtFrom: cachedSearchProps.sendCompletedAtFrom?.toString(),
		sendCompletedAtTo: cachedSearchProps.sendCompletedAtTo?.toString(),
		searchTerms: cachedSearchTerms,
		limit: pageSize,
		page
	};
	const key = queryKey(CAMPAIGN_SEARCH, searchPayload);

	const updateSearchTerms = useCallback((searchTerms?: string, immediately = false) => {
		setSearchTerms(searchTerms);
		// type check here because Polaris' Filters::onQueryChange passes other random, undocumented data as the second arg
		if (typeof immediately === 'boolean' && immediately) {
			setPage(0);
			setCachedSearchTerms(searchTerms);
		}
	}, []);

	const resetSearchTerms = useCallback(() => updateSearchTerms(initialSearchTerms, true), [initialSearchTerms, updateSearchTerms]);

	const hydrate = useCallback(
		(responseBody: ResponseBody): QueryReturn => ({
			totalResults: responseBody.totalResults,
			campaigns: hydrateCampaigns(responseBody.campaigns)
		}),
		[]
	);

	const {
		data,
		isLoading,
		isFetching: isSearching
	} = useQuery<QueryReturn, ErrorResponse>({
		queryKey: key,
		queryFn: () => post('/newsletters/search', searchPayload).then(hydrate),
		placeholderData: keepPreviousData,
		enabled
	});

	const searchResults = data?.campaigns ?? [];
	const totalResults = data?.totalResults ?? 0;

	const previousPageNumber = page - 1;
	const nextPageNumber = page + 1;

	const hasPrevious = previousPageNumber >= 0;
	const hasNext = pageSize * nextPageNumber < totalResults;

	const pagination: Pagination = {
		hasPrevious,
		hasNext,
		hasMultiplePages: hasPrevious || hasNext,
		onPrevious: () => {
			hasPrevious && setPage(previousPageNumber);
		},
		onNext: () => {
			hasNext && setPage(nextPageNumber);
		},
		setPage: (page: number) => setPage(page)
	} as const;

	return {
		isLoading,
		isSearching,
		searchResults,
		totalResults,
		pagination,
		searchTerms,
		updateSearchTerms,
		resetSearchTerms
	};
}

export function useUpcomingCampaigns(enabled = true, limit = 3) {
	const query = useSearchCampaigns(
		{
			limit,
			sortOrder: 'OLDEST_SCHEDULED',
			campaignStatuses: ['SCHEDULED', 'PROCESSING']
		},
		enabled
	);

	return {
		campaigns: query.searchResults,
		isLoading: query.isSearching
	};
}

export function useCampaignCount() {
	const query = useSearchCampaigns({
		campaignStatuses: ['CREATED', 'PAUSED', 'SCHEDULED', 'PROCESSING', 'COMPLETED']
	});

	return {
		campaignCount: query.totalResults,
		isLoading: query.isSearching
	};
}

export function useRecentlySentCampaigns(enabled = true, limit = 5) {
	const query = useSearchCampaigns(
		{
			limit,
			sortOrder: 'NEWEST_SEND_COMPLETED',
			campaignStatuses: ['COMPLETED']
		},
		enabled
	);

	return {
		campaigns: query.searchResults,
		isLoading: query.isSearching
	};
}

export function useFollowupCampaigns(campaignId: number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedCampaign[]>;
	const query = useQuery({
		queryKey: queryKey(FOLLOWUP_CAMPAIGNS, campaignId),
		queryFn: () => get(`/newsletters/${campaignId}/follow-ups`).then(hydrateCampaigns)
	});
	return { followupCampaigns: query.data ?? [], isLoadingFollowupCampaigns: query.isFetching };
}

export function useDeleteCampaign() {
	const { delete: deletion } = useAuthenticatedFetch();
	const { onCampaignDeleted } = useOnCampaignUpdated();

	const mutation = useMutation<void, ErrorResponse, number>({
		mutationFn: campaignId => deletion(`/newsletters/${campaignId}`),
		onSuccess: (_, campaignId) => onCampaignDeleted(campaignId)
	});

	return {
		deleteCampaign: (campaignId: number) => mutation.mutate(campaignId),
		isDeletingCampaign: mutation.isPending
	};
}

export function useUpdateCampaign(onSuccessHandler = () => {}) {
	const { put } = useAuthenticatedFetch() as Put<Campaign, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ invalidateEmailTemplate: true });

	const mutation = useMutation<Campaign, ErrorResponse, Campaign>({
		mutationFn: campaign => put(`/newsletters/${campaign.id}`, campaign).then(hydrateCampaign),
		onSuccess: campaign => {
			onCampaignUpdated(campaign);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		updateCampaign: (campaign: Campaign) => mutation.mutate(campaign),
		isUpdatingCampaign: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		isError: mutation.isError,
		resetMutation: mutation.reset
	};
}

export type BulkUpdateCampaignDiscountSettings = {
	campaignIds: number[];
	discountCodeType: DiscountCodeTypeValues;
	priceRuleId: number | null;
	discountCodeId: number | null;
};

export function useBulkUpdateCampaignDiscounts(onSuccessHandler = () => {}) {
	const { post } = useAuthenticatedFetch() as Post<BulkUpdateCampaignDiscountSettings, DehydratedCampaign[]>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ invalidateEmailTemplate: true });

	const mutation = useMutation<Campaign[], ErrorResponse, BulkUpdateCampaignDiscountSettings>({
		mutationFn: payload => post(`/newsletters/discounts:bulk-update`, payload).then(hydrateCampaigns),
		onSuccess: campaigns => {
			campaigns.forEach(onCampaignUpdated);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		bulkUpdateCampaignDiscounts: (settings: BulkUpdateCampaignDiscountSettings) => mutation.mutate(settings),
		isUpdatingCampaignDiscounts: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		isError: mutation.isError,
		resetMutation: mutation.reset
	};
}

export function usePauseCampaign(onSuccessHandler = () => {}) {
	const { patch } = useAuthenticatedFetch() as Patch<void, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated();

	const mutation = useMutation<Campaign, ErrorResponse, number>({
		mutationFn: campaignId => patch(`/newsletters/${campaignId}/pause`).then(hydrateCampaign),
		onSuccess: campaign => {
			onCampaignUpdated(campaign);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		pauseCampaign: (campaignId: number) => mutation.mutate(campaignId),
		isPausingCampaign: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		isError: mutation.isError,
		resetMutation: mutation.reset
	};
}

export function useScheduleCampaign(onSuccessHandler = () => {}) {
	type PatchBody = ScheduleSettings & { campaignGroupsEnabled: boolean };
	type MutationVariables = { campaignId: number; scheduleSettings: ScheduleSettings };

	const { patch } = useAuthenticatedFetch() as Patch<PatchBody, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ invalidateEmailTemplate: true });

	const mutation = useMutation<Campaign, ErrorResponse, MutationVariables>({
		mutationFn: ({ campaignId, scheduleSettings }) =>
			patch(`/newsletters/${campaignId}/schedule`, { ...scheduleSettings, campaignGroupsEnabled: true }).then(hydrateCampaign),
		mutationKey: [MutationKey.SCHEDULING_CAMPAIGN],
		onSuccess: campaign => {
			onCampaignUpdated(campaign);
			onSuccessHandler && onSuccessHandler();
		}
	});

	return {
		scheduleCampaign: (campaignId: number, scheduleSettings: ScheduleSettings) => mutation.mutate({ campaignId, scheduleSettings }),
		isSchedulingCampaign: mutation.isPending,
		serverSideErrors: mutation.error?.body ?? {},
		resetMutation: mutation.reset
	};
}

export function useCampaignPreview(campaignId: number, enabled: boolean) {
	const { get } = useAuthenticatedFetch() as Get<{ html: string; text: string; subject: string; previewText: string }>;

	const query = useQuery({
		queryKey: queryKey(CAMPAIGN_PREVIEWS, campaignId),
		queryFn: () => get(`/newsletters/${campaignId}/preview`),
		enabled: enabled && !!campaignId,
		placeholderData: keepPreviousData
	});

	return {
		campaignPreview: query.data,
		isLoadingCampaignPreview: query.isFetching
	};
}

export function useSetUTMCampaignOverride(campaignId: number) {
	type PatchBody = { utmCampaignOverride: string };

	const { patch } = useAuthenticatedFetch() as Patch<PatchBody, DehydratedCampaign>;
	const { onCampaignUpdated } = useOnCampaignUpdated({ invalidateEmailTemplate: true });

	const { mutate, isPending, error, reset } = useMutation<Campaign, ErrorResponse, string>({
		mutationFn: (utmCampaignOverride: string) =>
			patch(`/newsletters/${campaignId}/utm-campaign`, { utmCampaignOverride }).then(hydrateCampaign),

		onSuccess: campaign => onCampaignUpdated(campaign)
	});

	return {
		setUTMCampaignOverride: mutate,
		isSettingUTMCampaignOverride: isPending,
		serverSideErrors: error?.body ?? {},
		resetMutation: reset
	};
}

export function useCampaignPreviewContext(campaignId: number) {
	const { campaign, isLoadingCampaign } = useCampaign(campaignId);
	const { discountCode, isLoading: isLoadingDiscountCode } = useDiscountCodeById(
		campaign?.calculatedPriceRuleId,
		campaign?.calculatedDiscountCodeId
	);
	const previewContext = {} as Partial<PreviewContext>;

	if (campaign && discountCodeIsPresent(campaign.calculatedDiscountCodeType)) {
		const discountCodeType = campaign.calculatedDiscountCodeType;
		if (discountCode) {
			const priceRule = discountCode.priceRule;
			if (discountCodeType === 'SINGLE_CODE' && discountCode && priceRule) {
				previewContext.discountCode = discountCode;
				previewContext.priceRule = priceRule;
			} else if (discountCodeType === 'GENERATED' && priceRule) {
				previewContext.priceRule = priceRule;
			}
		}
	}

	return { previewContext, isLoadingPreviewContext: isLoadingCampaign || isLoadingDiscountCode };
}

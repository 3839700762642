import { Card, isNotBlank, ScrollToTop, Stack, useQueryParam, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import { AppProvider, Button, Layout, Page } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import translations from '@shopify/polaris/locales/en.json';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styled from 'styled-components';
import APIProvider from './APIProvider';
import App from './App';
import { QUERY_PARAM_ACTIVITY_ID, QUERY_PARAM_EXTENSION_ID } from './components/ExternalMarketingActivityRedirect';
import CustomLinkComponent from './CustomLinkComponent';
import EmbeddedMarketingProvider from './EmbeddedMarketingProvider';
import { createQueryClient } from './lib/ReactQueryConfig';
import InvalidationRegistry from './utils/InvalidationRegistry';

const APP_NAME = import.meta.env.VITE_APP_NAME;

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `platform-frontend@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://8b136ebf7ea04cff833322f5a6b6324c@o155661.ingest.sentry.io/1219243',
		integrations: defaults => [...defaults, Sentry.browserTracingIntegration()],
		tracesSampleRate: 1,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const queryClient = createQueryClient();
InvalidationRegistry.init(queryClient);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<EmbeddedMarketingProvider>
				<QueryClientProvider client={queryClient}>
					<Router>
						<ScrollToTop>
							<Routes>
								<Route path="/marketing_activity_editor" element={<DeprecatedExternalActivities />} />
								<Route path="/*" element={<App />} />
							</Routes>
						</ScrollToTop>
						<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} />
					</Router>
					<ReactQueryDevtools />
				</QueryClientProvider>
			</EmbeddedMarketingProvider>
		</APIProvider>
		{/* {import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production' && <WebVitalsSubmitter />} */}
	</StrictMode>
);

function DeprecatedExternalActivities() {
	const extensionUuid = useQueryParam(QUERY_PARAM_EXTENSION_ID);
	const activityId = useQueryParam(QUERY_PARAM_ACTIVITY_ID);

	return (
		<AppProvider i18n={translations} linkComponent={CustomLinkComponent}>
			<Page narrowWidth>
				<Layout sectioned>
					<Card>
						<Card.Section flush>
							<ImageContainer>
								<ExternalMigrationImage src="/images/external-activity-migration.webp" alt="Functionality moved" />
							</ImageContainer>
						</Card.Section>
						<Card.Section>
							<Stack alignment="center" vertical>
								<CenteredText>This functionality has moved to the Seguno Email app.</CenteredText>
								<Button
									variant="primary"
									url={`https://admin.shopify.com/apps/${APP_NAME}/external-marketing-activity?extension_id=${extensionUuid}${Boolean(activityId) ? `&activity_id=${activityId}` : ''}`}
									target="_top">
									Continue to Seguno Email
								</Button>
							</Stack>
						</Card.Section>
					</Card>
				</Layout>
			</Page>
		</AppProvider>
	);
}

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 1rem;
`;

const ExternalMigrationImage = styled.img`
	width: 100%;
	max-width: 300px;
`;

const CenteredText = styled.p`
	text-align: center;
`;

import { isNotBlank, queryKey, type Optional, type Timestamp } from '@segunosoftware/equinox';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Is } from '../utils/utils';
import { DISCOUNT_CODES_BY_ID, DISCOUNT_CODES_BY_VALUE, PRICE_RULES } from './query-keys';
import type { ErrorResponse, Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

/** @deprecated */
export const DiscountCodeType = Object.freeze({
	NONE: 'NONE',
	SINGLE_CODE: 'SINGLE_CODE',
	GENERATED: 'GENERATED',
	INHERITED: 'INHERITED'
});

export type DiscountCodeTypeValues = 'NONE' | 'SINGLE_CODE' | 'GENERATED' | 'INHERITED';

/** @deprecated */
export const DiscountEligibility = Object.freeze({
	ALL: 'ALL',
	NEW_PURCHASERS: 'NEW_PURCHASERS'
});

export type DiscountEligibilityValues = 'ALL' | 'NEW_PURCHASERS';
export type TargetType = 'line_item' | 'shipping_line';
export type TargetSelection = 'all' | 'entitled';

/** @deprecated */
export const ValueType = Object.freeze({
	FIXED_AMOUNT: 'fixed_amount',
	PERCENTAGE: 'percentage'
});

export type ValueTypeValues = 'fixed_amount' | 'percentage';
export type EntitledItemType = 'collection' | 'country' | 'product' | 'product_variant';
export type PrerequisiteItemType = 'customer_saved_search' | 'customer' | 'product' | 'product_variant' | 'collection';

export type PriceRuleEntitledItem = {
	itemId: number;
	itemType: EntitledItemType;
};

export type PriceRulePrerequisiteItem = {
	itemId: number;
	itemType: PrerequisiteItemType;
};

export type PriceRule = {
	id: number;
	title: string;
	value: number;
	entitledItems: PriceRuleEntitledItem[];
	prerequisiteItems: PriceRulePrerequisiteItem[];
	prerequisiteQuantity: number;
	entitledQuantity: number;
	prerequisiteAmount: number;
	targetType: TargetType;
	targetSelection: TargetSelection;
	valueType: ValueTypeValues;
	startsAt: Timestamp;
	endsAt: Timestamp;
	createdAt: Timestamp;
	updatedAt: Timestamp;
};

export type DiscountCode = {
	id: number;
	priceRule: PriceRule;
	code: string;
	expiresAt: Timestamp;
	createdAt: Timestamp;
	updatedAt: Timestamp;
};

export type DiscountCodeSettings = {
	discountCodeType: DiscountCodeTypeValues;
	priceRuleId?: number;
	discountCodeId?: number;
	discountEligibility?: DiscountEligibilityValues;
	autoExpireDisabled?: boolean;
	autoExpireDays?: number;
};

export function discountCodeIsEmpty(discountCodeType: DiscountCodeTypeValues) {
	return discountCodeType === 'NONE';
}

export function discountCodeIsPresent(discountCodeType: DiscountCodeTypeValues) {
	return !discountCodeIsEmpty(discountCodeType);
}

export function isValidDiscountCodeSettings(discountCodeSettings: DiscountCodeSettings, hasInvalidDiscountCode: boolean) {
	switch (discountCodeSettings.discountCodeType ?? '') {
		case 'NONE':
		case 'INHERITED':
			return true;

		case 'SINGLE_CODE':
		case 'GENERATED':
			return (
				Is.aPositiveInteger(discountCodeSettings.discountCodeId) &&
				Is.aPositiveInteger(discountCodeSettings.priceRuleId) &&
				!hasInvalidDiscountCode
			);

		default:
			return false;
	}
}

export function usePriceRule(priceRuleId: number) {
	const { get } = useAuthenticatedFetch() as Get<PriceRule>;
	const query = useQuery({
		queryKey: queryKey(PRICE_RULES, priceRuleId),
		queryFn: () => get(`/price-rules/${priceRuleId}`),
		enabled: Boolean(priceRuleId)
	});

	return {
		priceRule: query.data,
		isLoading: query.isFetching,
		isError: query.isError
	};
}

export function useDiscountCodeByValue(discountCode: Optional<string>) {
	const { get } = useAuthenticatedFetch() as Get<DiscountCode>;
	const queryClient = useQueryClient();

	const query = useQuery<DiscountCode, ErrorResponse, DiscountCode>({
		queryKey: queryKey(DISCOUNT_CODES_BY_VALUE, discountCode?.toLowerCase()),
		queryFn: () =>
			get(`/price-rules/discounts/${encodeURIComponent(discountCode ?? '')}`).then(discountCode => {
				if (discountCode?.priceRule?.id && discountCode?.id) {
					queryClient.setQueryData(queryKey(DISCOUNT_CODES_BY_ID, discountCode.priceRule.id, discountCode.id), discountCode);
					queryClient.setQueryData(queryKey(PRICE_RULES, discountCode.priceRule.id), discountCode.priceRule);
				}
				return discountCode;
			}),
		enabled: isNotBlank(discountCode)
	});

	const removeQuery = useCallback(() => {
		queryClient.removeQueries({ queryKey: queryKey(DISCOUNT_CODES_BY_VALUE, discountCode?.toLowerCase()) });
	}, [queryClient, discountCode]);

	return {
		discountCode: query.data,
		isLoading: query.isFetching,
		isError: query.isError,
		isNotFound: !query.isFetching && query.error?.status === 404,
		resetSearchQuery: removeQuery
	};
}

export function useDiscountCodeById(priceRuleId: Optional<number>, discountCodeId: Optional<number>) {
	const { get } = useAuthenticatedFetch() as Get<DiscountCode>;
	const queryClient = useQueryClient();

	const query = useQuery({
		queryKey: queryKey(DISCOUNT_CODES_BY_ID, priceRuleId, discountCodeId),
		queryFn: () =>
			get(`/price-rules/${priceRuleId}/discounts/${discountCodeId}`).then(discountCode => {
				if (isNotBlank(discountCode?.code)) {
					queryClient.setQueryData(queryKey(DISCOUNT_CODES_BY_VALUE, discountCode.code.toLowerCase()), discountCode);
				}
				if (discountCode?.priceRule?.id) {
					queryClient.setQueryData(queryKey(PRICE_RULES, discountCode.priceRule.id), discountCode.priceRule);
				}

				return discountCode;
			}),
		enabled: Boolean(priceRuleId && discountCodeId)
	});

	return {
		discountCode: query.data,
		isLoading: query.isFetching,
		isError: query.isError
	};
}

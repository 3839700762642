import { Assert, Is, getDateFromTimestamp, type Merge, type Timestamp } from '@segunosoftware/equinox';
import type { DiscountCodeTypeValues } from '../useDiscountCodes';

export type CampaignStatusValues = 'CREATED' | 'PAUSED' | 'SCHEDULED' | 'PROCESSING' | 'COMPLETED';

export type CampaignType =
	| 'PRODUCT_PROMOTION'
	| 'PRODUCT_LIST_PROMOTION'
	| 'COLLECTION_PROMOTION'
	| 'COLLECTION_LIST_PROMOTION'
	| 'BLOG_PROMOTION'
	| 'FEATURED_BLOG_PROMOTION'
	| 'SCRATCH';

export type TargetRecipientsValues =
	| 'ALL'
	| 'CUSTOMER_SAVED_SEARCHES'
	| 'INHERITED_DELIVERIES'
	| 'INHERITED_OPENS'
	| 'INHERITED_CLICKS'
	| 'INHERITED_NON_OPENS'
	| 'INHERITED_NON_CLICKS';

export type Campaign = {
	id: number;
	name: string;
	campaignType: CampaignType;
	campaignStatus: CampaignStatusValues;
	createdAt: Date;
	updatedAt: Date;
	scheduledAt?: Date;
	sendCompletedAt?: Date;
	expectedCompletionAt?: Date;
	emailTemplateId: number;
	parentCampaignId?: number;
	campaignTypeEmailTemplateId?: number;
	campaignGroupId?: number;
	marketingActivityId?: string;
	discountCodeType: DiscountCodeTypeValues;
	discountCodeId?: number;
	priceRuleId?: number;
	calculatedDiscountCodeType: DiscountCodeTypeValues;
	calculatedDiscountCodeId?: number;
	calculatedPriceRuleId?: number;
	language?: string;
	targetRecipients: TargetRecipientsValues;
	excludeRecentPurchasers: boolean;
	overrideNewsletterExclusion: boolean;
	computedUtmCampaign: string;
	utmCampaignOverride?: string;
	campaignGroupName?: string;
};

export type CampaignGroup = {
	id: number;
	name: string;
	isArchived: boolean;
	campaigns: Campaign[];
};

/**
 * This should not be used outside of campaign-related hook logic; TODO: fix
 */
export type DehydratedCampaign = Merge<
	Campaign,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
		scheduledAt?: Timestamp;
		sendCompletedAt?: Timestamp;
		expectedCompletionAt?: Timestamp;
	}
>;

/**
 * This should not be used outside of campaign-related hook logic; TODO: fix
 */
export function hydrateCampaign(dehydratedCampaign: DehydratedCampaign): Campaign {
	Assert.isDefined(dehydratedCampaign);
	Assert.isRealNumber(dehydratedCampaign.id);
	Assert.isRealNumber(dehydratedCampaign.createdAt);
	Assert.isRealNumber(dehydratedCampaign.updatedAt);

	Is.defined(dehydratedCampaign.scheduledAt) && Assert.isRealNumber(dehydratedCampaign.scheduledAt);
	Is.defined(dehydratedCampaign.expectedCompletionAt) && Assert.isRealNumber(dehydratedCampaign.expectedCompletionAt);
	Is.defined(dehydratedCampaign.sendCompletedAt) && Assert.isRealNumber(dehydratedCampaign.sendCompletedAt);

	return {
		...dehydratedCampaign,
		createdAt: getDateFromTimestamp(dehydratedCampaign.createdAt)!,
		updatedAt: getDateFromTimestamp(dehydratedCampaign.updatedAt)!,
		scheduledAt: getDateFromTimestamp(dehydratedCampaign.scheduledAt),
		expectedCompletionAt: getDateFromTimestamp(dehydratedCampaign.expectedCompletionAt),
		sendCompletedAt: getDateFromTimestamp(dehydratedCampaign.sendCompletedAt)
	};
}

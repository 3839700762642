import { Is, LocalDate } from '@segunosoftware/equinox';
import { composeGid } from '@shopify/admin-graphql-api-utilities';
import type { LabelFormatter } from '../hooks/types';
export { Assert, Is } from '@segunosoftware/equinox'; // TODO: Importers should import these directly.

/**
 * The maximum number of characters that most titles (names) can be: program name, campaign, name, etc.
 */
export const MAX_TITLE_LENGTH = 255;

function compose(namespace: string, id?: string | number) {
	return typeof id === 'undefined' ? undefined : composeGid(namespace, id);
}

export const getMarketingActivitySid = (activityId?: string | number) => {
	return compose('MarketingActivity', activityId);
};

export const localDateLabelFormatter: LabelFormatter = label => {
	if (!label) {
		return '';
	}
	if (typeof label === 'number') {
		return label.toString();
	}
	if (label.split('-').length === 3) {
		// This is a date with a specific day in it, let's render it in their short local date format
		return LocalDate.from(label).toLocaleDateString('en-us', { dateStyle: 'short' });
	}
	return label;
};

function toRealNumber(value: unknown, defaultValue = 0): number {
	const n = Number(value ?? defaultValue);
	return Is.aRealNumber(n) ? n : defaultValue;
}

function getCurrencyLabelFormatter(showCents = true): LabelFormatter {
	return value => `$${toRealNumber(value).toFixed(showCents ? 2 : 0)}`;
}

export const wholeDollarsLabelFormatter = getCurrencyLabelFormatter(false);
export const dollarsAndCentsLabelFormatter = getCurrencyLabelFormatter(true);

export const percentageLabelFormatter: LabelFormatter = value => `${toRealNumber(value)}%`;

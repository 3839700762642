import { LoadingScreen, isNotBlank, useQueryParam } from '@segunosoftware/equinox';
import { useEffect } from 'react';
import { useCampaignByMarketingActivityId } from '../hooks/campaigns/useCampaigns';
import { POST_PURCHASE_AUTOMATION_EXTENSION_UUID, useProgramByMarketingActivityId } from '../hooks/programs/usePrograms';
import { useNavigation } from '../hooks/useNavigation';
import { getMarketingActivitySid } from '../utils/utils';

export const QUERY_PARAM_EXTENSION_ID = 'extension_id';
export const QUERY_PARAM_ACTIVITY_ID = 'activity_id';

export default function ExternalMarketingActivityRedirect() {
	const { onNavigateCreateCampaign, onNavigateCreateProductPurchasedAutomation, onNavigateCampaign, onNavigateProgram } = useNavigation();

	const extensionUuid = useQueryParam(QUERY_PARAM_EXTENSION_ID);
	const activitySid = getMarketingActivitySid(useQueryParam(QUERY_PARAM_ACTIVITY_ID));

	const isProgram = extensionUuid === POST_PURCHASE_AUTOMATION_EXTENSION_UUID;
	const { campaign } = useCampaignByMarketingActivityId(isProgram ? undefined : activitySid);
	const { program } = useProgramByMarketingActivityId(isProgram ? activitySid : undefined);

	const hasActivitySid = isNotBlank(activitySid);

	useEffect(() => {
		if (hasActivitySid) {
			if (campaign) {
				onNavigateCampaign(campaign.id);
			} else if (program) {
				onNavigateProgram(program.id);
			}
		} else {
			if (isProgram) {
				onNavigateCreateProductPurchasedAutomation();
			} else {
				onNavigateCreateCampaign();
			}
		}
	}, [
		campaign,
		program,
		isProgram,
		hasActivitySid,
		onNavigateCampaign,
		onNavigateProgram,
		onNavigateCreateProductPurchasedAutomation,
		onNavigateCreateCampaign
	]);

	return <LoadingScreen />;
}

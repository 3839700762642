import { isNotBlank } from '@segunosoftware/equinox';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import type { BillingPackageType } from './useAccount';

// undefined parameter values will be ignored
function appendQueryParams(params: { [key: string]: string | number | undefined }) {
	const parsed = {
		...queryString.parse(document.location.search),
		...params
	};
	const queryParams = queryString.stringify(parsed);
	return isNotBlank(queryParams) ? `?${queryParams}` : undefined;
}

export function useIsMaxModal() {
	const location = useLocation();

	return useMemo(() => location.pathname.endsWith('/max-modal'), [location]);
}

export function useNavigation() {
	const navigate = useNavigate();

	return useMemo(
		() => ({
			onNavigateTemplates: () => navigate('/templates'),
			onNavigateArchivedTemplates: () => navigate('/templates/archive'),
			onNavigateEditCampaignTemplate: (id: number) => navigate(`/templates/${id}/editor`),
			onNavigateStoreTemplates: () => navigate('/templates/store'),
			onNavigateStoreCampaignPreview: (id: number) => navigate(`/templates/store/campaigns/${id}`),
			onNavigateBlogPostAnnouncementEditor: () => navigate('/blog-announcement/editor'),

			onNavigateBillingInitiate: (shop: string, billingPackageType?: BillingPackageType) =>
				navigate(`/oauth/shopify/billing/initiate?${queryString.stringify({ shop, billingPlan: billingPackageType })}`),
			onNavigateBillingPurchaseId: (shop: string, purchaseId: string) =>
				navigate(
					`/oauth/shopify/billing/app-purchase/initiate?shop=${encodeURIComponent(shop)}&purchaseId=${encodeURIComponent(purchaseId)}`
				),
			onNavigateBillingUpgrade: (shop: string, upgradeReason: string) =>
				navigate(
					`/oauth/shopify/billing/initiate?shop=${encodeURIComponent(shop)}&upgrading=true&upgradeReason=${encodeURIComponent(
						upgradeReason
					)}`
				),

			onNavigateCreateCampaign: (templateId?: number, campaignGroupId?: number) =>
				navigate({
					pathname: '/newsletters/new',
					search: appendQueryParams({ template: templateId, campaign: campaignGroupId })
				}),

			onNavigateCampaigns: () => navigate('/newsletters'),
			onNavigateCampaign: (id: number) => navigate(`/newsletters/${id}`),
			onNavigateEditCampaign: (id: number) => navigate(`/newsletters/${id}/editor`),
			onNavigateCampaignActivities: (id: number, filterType = '') => navigate(`/newsletters/${id}/activity/${filterType}`),

			onNavigateCreateCampaignGroup: (templateId?: number) =>
				navigate({ pathname: '/campaigns/new', search: appendQueryParams({ template: templateId }) }),

			onNavigateCampaignGroup: (id: number) => navigate(`/campaigns/${id}`),
			onNavigateCampaignGroups: () => navigate(`/campaigns`),

			onNavigateCustomerDetails: (externalId: number) => navigate(`/customers/${externalId}`),

			onNavigateProgram: (id: number) => navigate(`/automations/${id}`),
			onNavigateEditProgramMessage: (id: number) => navigate(`/automations/${id}/editor`),
			onNavigateProgramMessage: (id: number, messageId: number) => navigate(`/automations/${id}/emails/${messageId}`),
			onNavigateProgramMessageActivities: (programId: number, id: number, filterType: string = '') =>
				navigate(`/automations/${programId}/emails/${id}/activity/${filterType}`),
			onNavigateAutomationActivities: (id: number, filterType: string = '') => navigate(`/automations/${id}/activity/${filterType}`),
			onNavigateAutomations: () => navigate('/automations'),
			onNavigateCreateProductPurchasedAutomation: () => navigate('/automations/product-purchased'),
			onNavigateCreateCustomerTaggedAutomation: () => navigate('/automations/customer-tagged'),

			onNavigateConnections: () => navigate('/connections'),
			onNavigateConnectionDetails: (id: string) => navigate(`/connections/${id}`),
			onNavigateFacebookConnection: () => navigate('/connections/facebook'),
			onNavigateKlaviyoConnection: () => navigate('/connections/klaviyo'),
			onNavigateKlaviyoConnect: () => navigate('/klaviyo/connect'),
			onNavigateMailchimpConnection: () => navigate(`/connections/mailchimp`),
			onNavigateMailchimpConnect: () => navigate('/oauth/mailchimp/connect'),

			onNavigateSegunosCereal: () => navigate('/cereal'),
			onNavigateSegunosCerealGame: () => navigate('/cereal/pour-me-some'),

			onNavigateSettings: () => navigate('/settings'),

			onNavigateSubscribersReport: () => navigate('/reports/subscribers'),

			onNavigateReports: () => navigate('/reports'),

			onNavigateChangelog: () => navigate('/changelog'),

			onNavigateLanguage: (language: string) => navigate(`/translations/${language}`),

			onNavigateDashboard: () => navigate('/'),

			onNavigatePopups: () => navigate('/popups'),

			onNavigateReviews: () => navigate('/reviews'),

			onNavigateBackInStockRegistrants: () => navigate('/back-in-stock-registrants')
		}),
		[navigate]
	);
}

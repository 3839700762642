import { Loading, LoadingScreen, useQueryString } from '@segunosoftware/equinox';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import GA4UserProperties from './components/GA4UserProperties';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import ScreenCapture from './components/ScreenCapture';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import {
	ArchivedTemplateList,
	AutomationTriggerFlowActionEditor,
	AutomationsList,
	BackInStockRegistrants,
	BlogPostAnnouncementTemplateEditor,
	CampaignActivities,
	CampaignDetails,
	CampaignEditor,
	CampaignGroupDetails,
	CampaignList,
	CampaignTemplateEditor,
	Cereal,
	Changelog,
	ConnectionDetails,
	ConnectionsOverview,
	CreateCampaign,
	CreateCampaignGroup,
	CreateProgramOfType,
	CustomerDetails,
	CustomerSearch,
	Dashboard,
	EditorMaxModal,
	EngagementReport,
	ExternalMarketingActivityRedirect,
	MailchimpConnect,
	MailchimpFinishConnection,
	PopupsDashboard,
	ProgramActivities,
	ProgramDetails,
	ProgramEditor,
	ProgramMessageActivities,
	ReviewsDashboard,
	Settings,
	StoreCampaignGroupDetails,
	SubscribersReport,
	SuspendedAccount,
	TemplateList,
	TemplateStore,
	TranslationSettings,
	Welcome
} from './utils/lazy-load';

function isShopifyMobile() {
	return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify Mobile') >= 0;
}

export default function App() {
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapper>
				<Suspense fallback={<LoadingScreen />}>
					<Routes>
						<Route path="/oauth/mailchimp/connect" element={<ProtectedRoute element={<MailchimpConnect />} />} />
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
					</Routes>
				</Suspense>
			</SentryWrapper>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = !isShopifyMobile() && isFetching > 0;
	return (
		<>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="external-marketing-activity" element={<ExternalMarketingActivityRedirect />} />
					<Route path="automation_trigger_flow_action_editor" element={<AutomationTriggerFlowActionEditor />} />
					<Route path="changelog" element={<Changelog />} />
					<Route path="campaigns/*">
						<Route path="new" element={<CreateCampaignGroup />} />
						<Route path=":id" element={<CampaignGroupDetails />} />
						<Route path="" element={<CampaignList />} />
					</Route>
					<Route path="/external/*" element={<ExternalLinks />} />
					<Route path="newsletters/*">
						<Route path="new" element={<CreateCampaign />} />
						<Route path="template/:id/editor" element={<EditorMaxModal />} />
						<Route path="template/:id/editor/max-modal" element={<CampaignTemplateEditor />} />
						<Route path=":id/activity/:type" element={<CampaignActivities />} />
						<Route path=":id/activity" element={<CampaignActivities />} />
						<Route path=":id/editor" element={<EditorMaxModal />} />
						<Route path=":id/editor/max-modal" element={<CampaignEditor />} />
						<Route path=":id" element={<CampaignDetails />} />
						<Route path="" element={<CampaignList />} />
					</Route>
					<Route path="automations/*">
						<Route path=":id/editor" element={<EditorMaxModal />} />
						<Route path=":id/editor/max-modal" element={<ProgramEditor />} />
						<Route path=":id/activity/:type" element={<ProgramActivities />} />
						<Route path=":id/activity" element={<ProgramActivities />} />
						<Route path=":id/emails/:messageId/activity/:type" element={<ProgramMessageActivities />} />
						<Route path=":id/emails/:messageId/activity" element={<ProgramMessageActivities />} />
						<Route path=":id" element={<ProgramDetails />} />
						<Route path="product-purchased" element={<CreateProgramOfType programType="POST_PURCHASE" />} />
						<Route path="customer-tagged" element={<CreateProgramOfType programType="TAG_TRIGGER" />} />
						<Route path="" element={<AutomationsList />} />
					</Route>
					<Route path="templates/*">
						<Route path=":id/editor" element={<EditorMaxModal />} />
						<Route path=":id/editor/max-modal" element={<CampaignTemplateEditor />} />
						<Route path="archive" element={<ArchivedTemplateList />} />
						<Route path="store/*">
							<Route path="campaigns/:id" element={<StoreCampaignGroupDetails />} />
							<Route path="" element={<TemplateStore />} />
						</Route>
						<Route path="" element={<TemplateList />} />
					</Route>
					<Route path="blog-announcement/editor" element={<EditorMaxModal />} />
					<Route path="blog-announcement/editor/max-modal" element={<BlogPostAnnouncementTemplateEditor />} />
					<Route path="settings/mailchimp-finish" element={<MailchimpFinishConnection />} />
					<Route path="settings" element={<Settings />} />
					<Route path="translations/*">
						<Route path=":language" element={<TranslationSettings />} />
					</Route>
					<Route path="suspended" element={<SuspendedAccount />} />
					<Route path="connections/*">
						<Route path=":id" element={<ConnectionDetails />} />
						<Route path="" element={<ConnectionsOverview />} />
					</Route>
					<Route path="customers/*">
						<Route path="search" element={<CustomerSearch />} />
						<Route path=":externalId" element={<CustomerDetails />} />
						<Route path="" element={<Navigate to="/reports/subscribers" />} />
					</Route>
					<Route path="reports/*">
						<Route path="subscribers" element={<SubscribersReport />} />
						<Route path="" element={<EngagementReport />} />
					</Route>
					<Route path="cereal" element={<Cereal />} />
					<Route path="popups" element={<PopupsDashboard />} />
					<Route path="reviews" element={<ReviewsDashboard />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="back-in-stock-registrants" element={<BackInStockRegistrants />} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			<ScreenCapture />
			<GA4UserProperties />
			{calculatedLoading && <Loading />}
		</>
	);
}

function ExternalLinks() {
	const { id } = useQueryString();

	return (
		<Routes>
			<Route path="customers" element={<Navigate to={`/customers/${id}`} />} />
		</Routes>
	);
}

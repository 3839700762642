import * as Sentry from '@sentry/react';
import { AppProvider, Banner, Layout, Page } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { Component, useEffect, useState, type PropsWithChildren } from 'react';
import CustomLinkComponent from '../CustomLinkComponent';
import { useCachedAccount } from '../hooks/useAccount';

export default Sentry.withProfiler(SentryWrapper);

function SentryWrapper({ children }: PropsWithChildren) {
	const account = useCachedAccount();
	const [sentryContextId, setSentryContextId] = useState(0);

	useEffect(() => {
		if (account && account.id !== sentryContextId) {
			Sentry.setUser({
				id: account.id.toString(),
				username: account.shop,
				email: account.ownerEmail
			});
			setSentryContextId(account.id);
		}
	}, [account, sentryContextId]);

	return <SentryErrorBoundary>{children}</SentryErrorBoundary>;
}

class SentryErrorBoundary extends Component<PropsWithChildren> {
	override state = {
		error: null
	};

	override componentDidCatch(error: any, info: any) {
		this.setState({ error });
		Object.keys(info).forEach(key => {
			Sentry.getCurrentScope().setExtra(key, info[key]);
		});
		Sentry.captureException(error);
		Sentry.showReportDialog();
	}

	override render() {
		const { children } = this.props;
		const { error } = this.state;
		if (error) {
			return (
				<AppProvider i18n={translations} linkComponent={CustomLinkComponent}>
					<Page>
						<Layout sectioned>
							<Banner
								title="Something went wrong"
								tone="critical"
								action={{ content: 'Submit report', onAction: () => Sentry.showReportDialog() }}
								onDismiss={() => {
									window.location.reload();
								}}>
								<p>
									We're sorry, we hit an unexpected error. Our team has been notified, but it may help if you submit a report to help us fix
									the problem. You can also refresh your browser and see if the problem happens again.
								</p>
							</Banner>
						</Layout>
					</Page>
				</AppProvider>
			);
		}
		return children;
	}
}
